import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import I18nProvider from './modules/i18n';
import router from './router';
import StoreProvider from './store/Provider';
import ActiveJourneyProvider from './modules/journeys/ActiveJourneyProvider/ActiveJourneyProvider';
import UploadFeedbackModal from './modules/upload/modal/uploadFeedbackModal';
import GlobalDataLoader from './modules/globalDataLoader/GlobalDataLoader';
import GTMScript from './modules/gtm/gtmScript';
import GTMNoScript from './modules/gtm/gtmNoScript';

const App = () => (
  <HelmetProvider>
    <GTMScript />
    <GTMNoScript />
    <StoreProvider>
      <I18nProvider>
        <GlobalDataLoader />
        <ActiveJourneyProvider>
          <RouterProvider router={router} />
          <UploadFeedbackModal />
        </ActiveJourneyProvider>
      </I18nProvider>
    </StoreProvider>
  </HelmetProvider>
);

export default App;

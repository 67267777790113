import React from 'react';

import { getGtmId } from './gtm';

export default function GTMNoScript() {
  const gtmId = getGtmId();

  if (gtmId === null) return null;

  return (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
        height="0"
        width="0"
        style="display:none;visibility:hidden"
      ></iframe>
    </noscript>
  );
}
